import React, {useState} from 'react';
import {Nav, Navbar, Modal} from "react-bootstrap";
import {withRouter} from "react-router";
import Sticky from 'react-stickynode';
import {connect} from "react-redux";

import { toggleSignUpModal } from '../../Manage/actions'
import CTAButton from '../CTAButton/CTAButton'
import SignUpPage from 'Login/SignUpModal'

import './HomePageHeader.css';

const onManageClick = (history) => {
  if (localStorage.getItem('token')) {
    history.push('/manage');
  } else {
    history.push('/login');
  }
};

const HomeHeader = ({dispatch, history, showSignUpModal}) => {
  const [sticky, setSticky] = useState(false);

  const handleStateChange = (status) => {
    setSticky(status.status === Sticky.STATUS_FIXED);
  };

  const isLoginPage = window.location.pathname === '/login';

  return (
    <Sticky innerClass={sticky ? 'sticky' : ''} onStateChange={handleStateChange} innerZ={999}>
      <Navbar bg="white" expand="lg" collapseOnSelect>
        <Navbar.Toggle className="ms-3" aria-controls="responsive-navbar-nav" />
        <Navbar.Brand className="d-l-none d-block" href="/">
          <img className="home-header-logo" src="/assets/logo.png" alt="tiny.host" onClick={() => history.push('/')} />
        </Navbar.Brand>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-l-3">
            <Nav.Link className="menu-link" href="/blog/">
              Blog
            </Nav.Link>
            <Nav.Link className="menu-link" onClick={() => history.push('/help')}>
              Help
            </Nav.Link>
            <Nav.Link className="menu-link" onClick={() => history.push('/pricing')}>
              Pricing
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Navbar.Brand className="d-none d-l-block" href="/">
          <img className="home-header-logo" src="/assets/logo.png" alt="tiny.host" onClick={() => history.push('/')} />
        </Navbar.Brand>
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="me-auto" />
          {!isLoginPage && (
            <Nav>
              <Nav.Link>
                <CTAButton
                  className="m-auto"
                  label="Log in"
                  onClick={() => onManageClick(history)}
                  size="small"
                  variant="secondary"
                  showArrow={false}
                />
              </Nav.Link>
              <Nav.Link>
                <CTAButton
                  className="m-auto"
                  label="Sign up free"
                  onClick={() => dispatch(toggleSignUpModal(true))}
                  size="small"
                  variant="black"
                />
              </Nav.Link>
            </Nav>)
          }
        </Navbar.Collapse>
      </Navbar>
      <Modal show={showSignUpModal} onHide={() => dispatch(toggleSignUpModal(false))} centered>
        <div className="container p-3 ">
          <SignUpPage />
        </div>
      </Modal>
    </Sticky>
  )
};

const mapDispatchToProps = (dispatch) => ({dispatch});
const mapStateToProps = (state) => ({
  productId: state.manage.userProfile.productId,
  isLoading: !state.manage.userProfile.email,
  isChildAccount: !!state.manage.userProfile.childAccount,
  email: state.manage.userProfile.email,
  showSignUpModal: state.manage.showSignUpModal
});

export default connect(mapStateToProps, mapDispatchToProps)(withRouter(HomeHeader));
