import React from 'react';
import {withRouter} from "react-router";

import { SOURCE_LOGIN } from "../constants";
import Footer from "../components/Footer";
import HomeHeader from "../components/HomePageHeader";
import ProPlans from "../components/ProPlans";
import BorderButton from "../components/BorderButton/index.b";
import SignUpPage from './SignUpModal';

import './Login.css';

class Login extends React.Component {

  render() {
    return <div className="body-bg full-height">
      <HomeHeader/>
      <div className="container-login">
        <div className="container-left-b">
            <SignUpPage />
        </div>  
        <div className="container-right">
          <h5 className="text-start"><b>Upgrade for more</b></h5>
          <p className="text-start w-75">Upgrade to <b>host sites for longer</b> & manage them all from a simple
            dashboard and more.</p>
          <BorderButton className="text-start learn-more" label="Learn More →" onClick={() => this.props.history.push('/pricing')}/>
          <div className="container-cards mt-5">
            <ProPlans
              onProClick={this.showModal}
              source={SOURCE_LOGIN}
              hideTable
              hideFreeCard
            />
          </div>
        </div>
      </div>
      <Footer/>
    </div>
  }
}

export default withRouter(Login);
