import React from 'react'
import { withRouter } from 'react-router'

import { SOURCE_PRICING } from '../constants'
import HomeHeader from '../components/HomePageHeader'
import Footer from '../components/Footer'
import ProPlans from '../components/ProPlans'
import ProFeatures from '../components/ProFeatures/ProFeaturesB'
import MessageModal from 'components/MessageModal'
import text from './pricing.string'
import FeaturesBox from '../components/FeaturesBox/FeaturesBox'
import FAQ from '../Landing/FAQ/FAQ'

import './Pricing.css'

const Pricing = ({ history }) => {
  const strings = text['GB']

  const urlParams = new URLSearchParams(window.location.search)

  const errorMessage = urlParams.get('error_message')

  return (
    <div className="full-height body-bg">
      <HomeHeader />
      <div className="container-pricing w-100">
        <div className="w-100">
          <h3 className="bold text-start">Upgrade for more</h3>
          <p className="pricing-desc op-6 mb-5">{strings.desc}</p>
          <ProPlans onFreeClick={() => history.push('/')} source={SOURCE_PRICING} showFreeCard />
          <ProFeatures strings={strings} hideTitle />
          <FeaturesBox title={<span className="colorText">Do more with a link</span>} className="mt-lg-7" />
          <FAQ />
        </div>
      </div>
      {errorMessage && (
        <MessageModal
          show={errorMessage}
          title="Payment Failed"
          message={errorMessage}
          error={errorMessage}
          handleNeutral={() => history.push('/pricing')}
        />
      )}
      <Footer />
    </div>
  )
}

export default withRouter(Pricing)
