import React from 'react';
import Footer from "../Footer";
import HomeHeader from "../HomePageHeader";
import {Button} from "react-bootstrap";
import {Helmet} from "react-helmet";

const NotFound = () => <>
    <Helmet>
        <meta name="robots" content="noindex,nofollow" />
    </Helmet>
    <div className="full-height">
        <HomeHeader/>
        <img className="mt-5" src="/assets/icons/warning.svg" height="120" />
        <h1 className="bold mt-5">Page not found</h1>
        <p className="mt-3">
            Oops, that page doesn't exist
        </p>
        <Button
            className="mt-3 mb-130"
            href="/"
        >
            Go home
        </Button>
        <Footer/>
    </div>
</>;

export default NotFound;